<div class="article-link">
  @if (shouldOpenInternally()) {
    <a [routerLink]="getUrl()" queryParamsHandling="merge" [attr.data-trackid]="article.key">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  } @else {
    <a [href]="getUrl()" target="_blank" [attr.data-trackid]="article.key">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  }

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</div>
