<div class="container">
  <app-page-title [text]="'articles.articlesOverview.pageTitle' | fms"></app-page-title>

  <div class="filters">
    <stb-chip [formControl]="articles" (chipClicked)="setFilter($event.chip)" chipType="choice"></stb-chip>
  </div>

  @if (isEnglish) {
    <stb-alert type="info" width="fluid">
      {{ "articles.articlesOverview.noTranslationsAlert" | fms }}
    </stb-alert>
  }

  <div class="articles">
    @for (article of articles$ | async; track trackArticleBy($index, article)) {
      <app-article-link [article]="article">
        <app-article-card [article]="article"></app-article-card>
      </app-article-link>
    }
  </div>

  <h2 class="stb-mb2">{{ "articles.articlesOverview.faqTitle" | fms }}</h2>
  <app-articles-faq></app-articles-faq>
</div>
