import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { hasShadowDom, removeQueryParams } from "../../../../../utils/utils";
import { RouteKey, routes } from "../../../../articles/routes";
import { DynamicArticle } from "../article-card/article-card.component";
import { TrackIdDirective } from "../../../directives/track-id/track-id.directive";
import { RouterLink } from "@angular/router";
import { NgTemplateOutlet } from "@angular/common";
import { HrefDirective } from "../../../directives/href.directive";

@Component({
  selector: "app-article-link",
  templateUrl: "./article-link.component.html",
  styleUrls: ["./article-link.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TrackIdDirective, RouterLink, NgTemplateOutlet, HrefDirective],
})
export class ArticleLinkComponent {
  @Input()
  public article!: DynamicArticle;

  public shouldOpenInternally(): boolean {
    return (hasShadowDom() && !!this.article.key) || (!this.article.external && !!this.article.url);
  }

  public getUrl(): string | string[] {
    const { key, url, external } = this.article;

    if (this.shouldOpenInternally()) {
      return external ? `/${routes[RouteKey.Root]}/${routes[RouteKey.Article]}/${key}` : url;
    }

    return removeQueryParams(url, ["headless=true"]);
  }
}
